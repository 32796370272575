
::v-deep {
    .v-list-item {
        z-index: 1;
        font-size: var(--txt-font-size-sm);
        color: var(--v-grey-base);
        &--active {
            font-weight: 500;
            color: var(--v-grey-darken4);
            &::before {
                z-index: -1;
                opacity: 1;
                background-color: var(--v-grey-lighten5);
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    ::v-deep {
        .v-list-item {
            min-height: 56px;
            padding: 0 24px;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
